import { Frequency, FrequencyType } from '~/models/documents/jDocument'
import { CronExpression } from 'cron-parser'
import { JReport } from '~/models/documents/jReport'
import _ from 'lodash'

export const generateCronDate = (frequency: Frequency) => {
  if (frequency.type === FrequencyType.minute) {
    return `*/${frequency.every} * * * *`
  }
  if (frequency.type === FrequencyType.hour) {
    return `0 */${frequency.every}  * * *`
  }
  return `0 0 */${frequency.every} * *`
}

export const getTodayCronDates = () => {
  const today = new Date()

  const startOfDay = new Date(today.setHours(0, 0, 0, 0))
  const endOfDay = new Date(today.setHours(23, 59, 59, 999))

  const dates: string[] = []
  let date = _.cloneDeep(startOfDay)
  while (date.getTime() <= endOfDay.getTime()) {
    if (date.getTime() >= startOfDay.getTime()) {
      dates.push(getTimeFrequencyDate(date))
    }
    date = nextDate(date, {
      every: 5,
      type: FrequencyType.minute,
      startingAt: new Date(),
      startWithReportLaunch: false,
    })
  }

  return dates
}

export const getTimeFrequencyDate = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}
export const getStartInterval = (frequency: Frequency, startDate: Date) => {
  if (!frequency.every)
    throw new Error(
      'FrequencyHelper.getStartInterval - Frequency.every is required',
    )

  let startDay = _.cloneDeep(startDate)
  startDay.setHours(0, 0, 0, 0)

  let date = _.cloneDeep(startDay)
  while (date.getTime() < startDate.getTime()) {
    date = nextDate(date, frequency)
    if (date.getTime() < startDate.getTime()) {
      startDay = _.cloneDeep(date)
    }
  }

  return startDay
}

export const getNthColumnDate = (
  frequency: Frequency,
  startDate: Date,
  index: number,
) => {
  const date = addFrequencyTime(
    startDate,
    frequency.type,
    frequency.every * index,
  )

  return getTimeFrequencyDate(date)
}

export const getJustifiedDateFromInterval = (
  interval: CronExpression,
  startingDate: Date,
  frequency: Frequency,
) => {
  let date = interval?.next()?.toDate()
  if (!date) return

  if (!frequency.startWithReportLaunch) {
    date = new Date(
      date.getTime() -
        (getStartInterval(frequency, startingDate)?.next()?.toDate() -
          startingDate),
    )
  }
  return date
}

export const nextDate = (date: Date, frequency: Frequency) => {
  if (!frequency.every) return date

  return addFrequencyTime(date, frequency.type, frequency.every)
}

export const addFrequencyTime = (
  date: Date,
  type: FrequencyType | null,
  every: number,
) => {
  switch (type) {
    case FrequencyType.minute:
      date.setMinutes(date.getMinutes() + every)
      break
    case FrequencyType.hour:
      date.setHours(date.getHours() + every)
      break
    case FrequencyType.day:
      date.setDate(date.getDate() + every)
  }

  return date
}
export const getReportStartingTime = (
  frequency: Frequency,
  report: JReport,
) => {
  if (
    !frequency.startWithReportLaunch ||
    !frequency.enabled ||
    !frequency.every
  ) {
    const startingAt = frequency.startingAt.split(':')

    const currentDate = new Date()
    currentDate.setHours(startingAt[0])
    currentDate.setMinutes(startingAt[1])
    currentDate.setSeconds(0)

    return currentDate
  }

  return getStartInterval(frequency, new Date(report.creation_date))
}
