actions:
  alerts: Alerts
  new_goal: New goal
  pin_column: Pin
  quick_actions: More actions
  report_alert: Alert
  report_alert_description: 'Detail the report and select the different people to be alerted :'
  unpin: Unpin
  unpin_column: Unpin column
alerts:
  add_comment: Comment on the alert or add some details...
  alert_center: Alerts center
  assigned_to: assigned alert to
  comment: Comment
  get_more: Get { count } more alert | Get { count } more alerts
  information_about_alerts: Additional information about the alert
  load_more: Get more alerts
  minimum_one_justification: You must provide a justification
  minimum_one_selected_people: At least one people must be assigned to the alert
  my_interventions: My interventions
  new_alert: New alert
  no_justification_provided: No justification has been provided
  no_saved_answers: No answer has been pre-registered
  no_alerts: No alerts
  people_to_alert: 'People or groups to mention:'
  raised_by_me: Raised by me
  removed_from: has removed
  search_placeholder: Search by document name
  select_users_to_alert: Select user(s) to mention
  status_history: Status history
  to_signal: 'To signal:'
analysis:
  analysis: Analysis
application_fields:
  all_operations: All operations
  all_operations_search: 'All operations containing: { entry }'
  all_products: All products
  all_products_search: 'All products containing: { entry }'
  all_workplaces: All workplaces
  all_workplaces_search: 'All workplaces containing: { entry }'
  application_fields: Application Fields
  charging-station: Charging Station
  operations: Operations
  product: Product
  products: Products
  reference: References
  select_all_operations: Select all operations
  select_all_products: Select all products
  select_all_workplaces: Select all workplaces
  select_operation: Select an operation...
  select_product: Select a product...
  select_workplace: Select a workplace...
  workplaces: Workplaces
authentication:
  wrong_authentication_method: your user doesn't have the permission to connect using a password, please refer to your administrator for more information.
batch_record:
  batch_record_created: The document has been created and attached to the  selected production orders.
  batch_record_creation_error: An error occurred while creating the document.
  add_comment: Add a comment
  batch: PO
  batch_sumup: See OF summary
  delete_document_from_batch_description: Are you sure you want to delete this attachment file? This action cannot be undone.
  delete_document_from_batch_title: Delete an attachment file
  documents: PO documents
  information: PO information
  last_released_by_title: Latest PO release
  modal_release_description: You are going to release the PO. Make sure the PO is fully compliant before validating. Please enter your login details to confirm.
  modal_release_title: Are you sure you want to release the PO { name }?
  new_document: New document
  release: Release
  release_batch_login_error: Wrong credentials.
  release_batch_rights_error: You do not have the necessary rights to perform this action.
  release_title: PO release
  release_title_sidebar: Batch release
  released_batch: PO released at { date } by
  update_document: Update document
  update_report: Update report
  update_attachment: Update attachment
bread_crumb:
  documents: Documents
  new_view: New view
  production_orders: All production orders
  simulation: Simulate a production order
  templates: Templates
  views: All reports
button:
  about: About
  back: Back
  go: GO
  home: Home
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
dashboard:
  empty_dashboard: No dashbord has been selected select one from the list to see it here
  last_30_days: Last 30 days
  select: Select a dashboard
  select_dashboard_placeholder: Select
dates:
  day: '{amount} day ago | {amount} days ago'
  hour: '{amount} hour ago | {amount} hours ago'
  minute: '{amount} minute ago | {amount} minutes ago'
  month: '{amount} month ago | {amount} months ago'
  second: '{amount} second ago | {amount} seconds ago'
days:
  daily: Daily
  friday: Friday
  monday: Monday
  saturday: Saturday
  sunday: Sunday
  thursday: Thursday
  tuesday: Tuesday
  wednesday: Wednesday
display:
  all_columns: All columns
  all_lines: All lines
  editable_columns: Editable columns
  editable_lines: Editable lines
document:
  add_new_block: Add a new block
  application_field: Application fields
  archive: Archive document
  starts_with_report_launch: The moment of the operation launch
  archive_confirmation_message: Are you sure you want to archive this document? This action cannot be undone.
  ask_admin: Please contact the administrator
  cant_finish_document: There are still some entries to be made
  cant_finish_document_description: You cannot complete this document without having entered the cells on the mandatory (non-optional) lines. However, you can fill in "N/A" in the cells concerned or raise an alert about them.
  cant_finish_document_description_mandatory_lines: 'The following lines are mandatory:'
  capture: Capture
  category: Category
  cells_missing: '{number} unfilled cell(s)'
  column_number: Column number
  columns: Columns
  confirm_archive_in_document:
    description: You are about to archive this document. Once archived, this document will no longer be available for use in the PO. Are you sure you want to proceed?
    title: Confirm Archive
  confirm_archive_many_documents:
    description: You are about to archive 1 document. This document will no longer be available for use in the PO. | You are about to archive {nbDocument} documents. These documents can no longer be used in the PO.
    title: Confirm archiving
  connect_to_balance: Connect the device
  create: New document
  create_new_document: Confirm changes?
  create_new_document_message: You are going to create the first version of this document
  create_new_version: Create a new version?
  create_new_version_message: You are going to change the document version by saving it
  created_by: Created by
  created_on: Created on
  cycle_of_document: Cycle of document
  delete: Delete document
  delete_confirmation_message: Are you sure you want to delete this document? This action cannot be undone.
  device: Device
  display: Display
  display_parameters: Display settings
  document_archive_message: The document has been successfully archived
  document_archive_title: Archived document
  document_creation_message: The document has been successfully created
  document_delete_message: The document has been successfully deleted
  document_to_model_success: Document successfully converted to a template
  document_update_error_message: An error has occured when saving the document
  document_duplicate_error_message: An error has occured when duplicating the document
  document_update_message: The document has been successfully modified
  enter: Enter
  exit_without_saving: Exit without saving
  expiration_date: Expiration date
  file: File
  file_extension_error: The extension is not authorized
  finish: Finish document
  finish_confirmation_message: Finishing the document will finish it for the current production order, are you sure you want to proceed?
  format: Format
  frequency: Frequency
  get_more: Get { count } more document | Get + { count } more documents
  get_more_revisions: Get { count } more revision | Get { count } more revisions
  input_frequency: Input frequency
  input_frequency_activate: Enable frequency inputs
  last_updated_on: Last updated on
  lifecycle: Lifecycle
  lines: Lines
  load_more: Get more documents
  load_more_revisions: More revisions
  measures: Measure
  name: Name
  new_document: New document
  no_files: There are no files available
  no_workplace_documents: No document available on this workplace
  non_valid: not compliant
  not_found: No document found
  note: Note
  notify_operator: Notify operator
  number_of_columns: Number of columns
  order: Order
  production_order: Production order
  publish_document: Publier le document
  display_column_one_by_one: Display columns one by one
  schedule:
    as_soon_as: As soon as
    as_soon_as_the_publication: Once published
    available_from: Available from
    availablity: Availability
    day: Day
    days: Days
    document_is_accessible: The document will be available
    document_is_accessible_every: The document will be available every
    end: End
    end_at: End at
    every: Every
    every_day: Everyday | Every {nb} days
    every_f: Every
    every_feminine: Every
    every_m: Every
    every_month: Every month | Every {nb} months
    every_week: Every week | Every {nb} weeks
    every_week_article: Every
    every_week_days: Every {days}
    every_with_article: Every
    every_year: Every year | Every {nb} years
    from_date: from {date}
    hour: Hour
    minute: Minute
    month: Month
    months: Months
    no_end: No ending date
    no_schedule: No schedule
    no_start: No starting date
    no_starting_date: No starting date
    not_recognized: Recurrence type isn't recognized
    permanently_available: Permanently available
    publication_date: Publication date
    recurrence: Recurrence
    recurrence_from: From
    recurrence_to: To
    repeat: Repetition
    repeat_each: Repeat each
    repeat_each_feminine: Repeat each
    set_hour: Set hour
    set_hour_error: The end time must be greater than the start time.
    specific_recurrence: Specific recurrence
    start: Start
    start_at: Start at
    the: The
    the_f: The
    the_feminine: the
    the_m: The
    the_masculine: the
    the_plurial: The
    until_date: until {date}
    week: Week
    weeks: weeks
    year: Year
    year_feminine: Years
    years: Years
  scheduling: Scheduling
  scheduling_subtitle: Schedule the display of the document in the operation area
  search: Search a document
  select_application_fields: Select application fields
  simulate: Simulate
  start_date: Starting date
  start_to_create_a_new_document: Start to create a new document
  starting_at: Starting at
  starting_with: Starting at
  state: State
  status: Status
  status_active: Active
  status_archived: Archived
  status_deleted: Deleted
  status_draft: Draft
  status_finished: Finished
  status_in_progress: In progress
  status_in_review: In review
  status_inactive: Inactive
  status_new: New
  status_published: Published
  status_qualified: Qualified
  status_released: Released
  status_being_processed: being processed
  status_resolved: Resolved
  status_select: Select
  step_check: OK/NOK
  step_checkbox: Checkbox
  step_file: File
  step_link: Link
  step_measure: Measure
  step_number: Number
  step_calculator: Calculator
  step_options_list: Options list
  step_photo: Picture
  step_photo_no_camera_access: Camera access denied. Please grant the access on your browser settings.
  step_photo_no_devices_found: No device found. Please connect a USB Camera
  step_photo_preview_picture: Preview picture
  step_photo_take_picture: Take a picture
  step_photo_add_picture: Add a picture
  step_photo_take_picture_again: Retake picture
  step_photo_take_picture_description: Use your camera to take a photo. Click "Capture" to save the photo and preview it for 2 seconds before the modal automatically closes.
  step_photo_update_picture: You can update your existing photo by using the camera. Click on "Take a picture" to save the new photo, and it will be previewed for 2 seconds before the modal automatically closes, replacing the previous one.
  step_photo_update_picture_description: Update picture description
  step_text: Text
  step_time: Time
  steps_linked_to_template: Some steps are already linked to a template
  title: My Documents
  to_fill: To fill
  trigger: Trigger
  trigger_element: Triggered on
  type: Document type
  type_auto: Automatic (NIR)
  type_manual: Manual
  type_readonly: Lecture seule
  uncertain: uncertain
  unlink_steps: First disassociate the already imported blocks from a template.
  unsaved_changes_description: Any changes will be lost if you don't save this document
  unsaved_changes_title: This document is not saved
  update_research: Update search
  updated_at: Updated at
  updated_by: Updated by
  updated_on: Updated on
  use_as_template: Use as a template
  valid: compliant
  version: Version
  version_reason_add: Add a note about version upgrade
  version_reason_error: You must justify the version change
  workflow: Workflow
  you_have_completed: You have
error:
  email_already_in_use: Email already in use
  nfc_code_policy_not_valid: The code must have 16 characters
  not_same_old_password: The password cannot be the same as the old password
  password_policy_not_valid: The password must have a minimum of 8 characters and include lowercase and uppercase letters
  passwords_not_match: Passwords do not match
export:
  at: at
  attachments_of_document: Attachments_of_the_document
  change_target: Dynamic target
  columns_count: Number of columns
  comment: Comment
  created_at: Created at
  created_by: Created by
  description: Description
  captured_data: Captured data between
  document_export_error: An error was encountered while working on your document.
  download_error: An error was encountered while connecting to the server.
  download_is_starting: Your  download will start in a second...
  export_document_as_pdf: Export document in a pdf format
  export_type: Export type
  goal: Goal
  historic_value: Historic value
  justification: Justification
  master_session_status_history: Work order's status history
  max: Max
  min: Min
  modified_at: Updated at
  modified_by: Updated by
  name: Name
  operator: Operator
  report_title: Report's Title
  status: Status
  status_history: Report's status history
  step_type: Type
  tags: Tags
  tool: Measurement tool
  type: Type
  unit: Unit
  update_date: Filled at
  value: Value
  workplaces: Workplaces
filters:
  add: Filter reports
  assignation: Assignation
  assignee: Assigne
  model: Incident form
  incident_form: Incident form
  at_least_one_display_column: At least one option must be chosen
  blocker: Blocker
  blockers: Blocker
  categories: Category
  choose_filter: Choose a filter
  date: Date
  display: Display
  display_columns: Displayed columns...
  documents: Document
  filter: Filter
  filter_by: Filter by
  go_back: Back to filter choices
  group_by: Group by
  last_14_days: Last 14 days
  last_24_hours: Last 24 hours
  last_3_days: Last 3 days
  last_7_days: Last 7 days
  last_month: Last month
  machines: Workplaces
  models: Models
  no_grouping: No grouping
  operation: Operation
  operations: Operation
  product: Product
  production_orders: Work order
  products: Product
  raised_by: Raised by
  remove_all: Remove all
  role: Role
  search: Search filter...
  select_a_date: Select a date
  status: Status
  teams: Team
  templates: Templates
  title: Filters
  today: Today
  users: Operator
  work_order: Work order
  workplace: Workplace
  workplaces: Workplace
  yesterday: Yesterday
firebase:
  too_many_attempts: Your account is temporarily disabled, you have made too many attempts. Please reset your password
  unexpected_error: An unexpected error has occurred. Please refresh the page
  user_disabled: Your account has been disabled
  weak_password: Password should be at least 6 characters
  wrong_actual_password: Incorrect current password
  wrong_email: Wrong email address
  wrong_password: incorrect password
format:
  fixed: Fixed
  formula: Formula
  quantity: Quantity
  unique: Unique
  unlimited: Unlimited
global:
  automatic_machine: automatic machines
  validation_error: Error when importing your file, please check your data
  updated_by: Updated by
  modify: Modify
  advance: Advance
  delay: Delay
  remaining_time: Remaining time
  no_iot_connected: No IOT connected
  display_NC_only: Display only columns with NC
  step: Step
  steps: Steps
  cell: Cell
  history_annex: historical Annex
  pictures_annex: Image blocks annex
  column: Column | Columns
  account_and_access: Users
  document_attachment_annex: Annex of documents attached to the report
  step_attachment_annex: Annex of documents attached to the steps
  actions: Actions
  result: Result
  add: Add
  next: Next
  add_a_attachment_file: Add an attachment file
  add_user: Add user
  ask_site_admin: Please contact the site administrator
  no_rights: You have no rights
  additional_informations: Additional informations
  alert: Alert | Alerts
  alerts: Alerts
  alerts_success: Successfully alerted!
  all: All
  allTeams: All teams
  all_the_documents: All the Documents
  all_the_of: All the OF
  all_the_templates: All the Templates
  analyses: Analytics
  and: and
  application_fields: Fields of application
  archive: Archive
  are_you_sure_you_want_to_delete_user: Are you sure you want to this user?
  are_you_sure_you_want_to_logout: Are you sure you want to logout
  at: at
  attachment: Attached files
  attachments: Attachments
  attention_any_unsaved_document_will_be_lost: Attention any unsaved Documents will be lost !!
  audit_logs: Connection logs
  back_home: Go back home
  beforeOrAfter: Before or after
  both_passwords_dont_match: Both passwords don't match
  bread_crumb:
    documents: documents
    simulation: simulation
    templates: templates
  cancel: Cancel
  change_language_to_apply: It is strongly recommended to refresh the page to apply the changes
  change_password: Change password
  choice: choice | choices
  click_button_reload: Please click on the button below to reload !
  client: Client
  clients: Clients
  close: Close
  complete: Complete
  confirm: Confirm
  confirm_import: Confirm data import
  confirm_import_desc: Importing this file will update customer data previously imported into JUNO
  confirm_modification: Confirm changes ?
  confirm_password: Confirm password
  confirm_password_validation_label: Both passwords
  confirmation_password: Confirm password
  content: Content
  copy_of: Copy of
  create: Create
  create_setting_success_message: The setting has been successfully created
  created_at: Created at
  created_by: Created by
  created_on: Created on
  current_language: eng
  current_password: Current password
  dashboard: Dashboard
  days: Days
  define_as_a_template: Define as a template
  delete: Delete
  delete_setting_success_message: The setting has been successfully deleted
  description: Description
  disconnected: You have been disconnected
  disconnected_for_inactivity: Due to prolonged inactivity, you are disconnected.
  display: Display
  document: document | document | Documents
  document_parameters: Document parameters
  documents: Documents
  download: Download
  download_as_pdf: Download as pdf
  duplicate: Duplicate
  duplicate_on_destination_site: Destination site
  duplicate_no_language_selected: No language selected
  duplicate_no_site_selected: No site selected
  duplicate_and_translate: Translate the document
  duplicate_on_another_site: Duplicate on another site
  duplicate_on_another_site_successful: The document has been successfully duplicated on the destination site
  edit: Edit
  element: No element | 1 element | {n} elements
  element_not_found: No element found
  email: Email
  empty_list: No records found
  enriched: Enriched
  enriched_export: Enriched Export
  error: Error
  export: Export
  files: Files
  filter: Filter | Filters
  finish_document: End the document
  finish_entry: Finish my entry
  finished: Finished
  first_name: First name
  for: For
  forbidden: Forbidden access to this page
  forbidden_message: Please check the URL in the address bar and try again.
  force_reset_password: You are logging in for the first time, please reset your password
  from: from
  ftp_parameters: FTP
  group: Group | Groups
  hello: Hello
  history: History
  id: Id
  if: If
  import: Import
  import_export_parameters: Import/Export
  inProgress: in progress
  in_progress: In progress
  instructions: Instructions
  integration_parameters: Integrations
  integrations: Integrations
  justification: Justification
  label: Label
  last_modification: Last modification
  last_name: Last name
  library: Library
  link: Link
  list: List
  loading: Loading...
  login: Login
  logout: Logout
  mandatory: Mandatory
  mark_as_read: Mark everything as read
  media_library: Media library
  minutes: Minutes
  modeles: templates
  monitoring: Monitoring
  month: Month
  my_dashboards: My dashboards
  name: Name
  name_document: Name of the document
  nb_of_boxes: Number of boxes
  need_reset_password: Do you need to reset your password ?
  network_error: No internet connexion
  new: New
  new_password: New password
  new_version: A new version of Juno is available
  next_check_at: A check need to be done on step {step} of report {reportName}
  'no': 'No'
  no_value: No value
  notification_parameters: Notifications
  number_of_steps: '{number} step | {number} steps'
  ok: Ok
  operation: Operation
  operator_interface: Operator space
  optional: Optional
  or: Or
  other: Other
  out_of: out of
  parameters: Parameters
  parent: Parent
  password: Password
  password_expired: Your password has expired, please reset you password
  password_has_changed: Your password has been modified
  password_update_error_message: An error has occurred while changing the password
  people: '{amount} people'
  per_page: per page
  percentage: percentage of tolerance
  performances: Performances
  permissions: Roles and permissions
  picture: Picture
  please_select_type: Please select a type
  preview: Preview
  product: Product
  publish: Publish
  range: range
  reason: Reason
  refresh: Refresh
  reload: Reload
  remove_attachment: Remove attachment
  report: Report | Reports
  reports: Reports
  reset_email_sent: An email to reset your password has been sent. Please click on the link when you get it
  resolved: Resolved
  restore: restore
  revision: Revision
  role: Role
  roles: Roles
  row: Row | Rows
  save: Save
  save_preferences: Save preferences
  search: Search
  search_assignee: Assign for
  seeAll: See all
  seeDescription: see description
  select: Select
  selectTeam: Select a Team
  select_tab: Select a tab
  select_type: Select a type
  selected_documents: '{n} selected document | {n} selected documents'
  selected_documents_from_to: '{from}-{to} on a total of {total} documents'
  selected_lookups_from_to: '{from}-{to} on a total of {total} lookups'
  selected_products_from_to: '{from}-{to} on a total of {total} products'
  selected_workplaces_from_to: '{from}-{to} on a total of {total} workplaces'
  selected_production_orders_from_to: '{from}-{to} on a total of {total} work orders'
  selected_production_orders: '{n} selected report | {n} selected reports'
  selected_reports_from_to: '{from}-{to} on a total of {total} reports'
  send: Send
  send_reset_email: Send reset password email
  settings: Settings
  sign_out: Sign out
  simple_export: Simple Export
  simulation: Simulation
  site_management: Site management
  sites: Sites
  complementary_sites: Complementary sites
  start: Start
  startTask: Start task
  status_management: Status management
  steps_parameters: Steps parameters
  success: Success
  summary: Synthesis
  switch_to_operator: Production space
  switch_to_supervisor: Supervision space
  switch_user: Switch user
  switch_user_description: You are about to switch the user account. Upon confirmation, you will be redirected to the login page and you will have to enter the credentials of another user.
  switch_user_title: Switch user
  tags: Tags
  template: Template
  template_messages_parameters: Template messages
  templates: Templates
  then: Then
  time: Temps
  title: Title
  to_consult: To consult
  today: Today
  total_of_controles: Total number of controls
  total_parts: Total parts
  total_report: Total reports
  understood: I understood
  unknown: Unknown
  unlink_step: Are you sure you want to unlink this block from its template ?
  unlink_step_message: 'Editing this block implies that it can no longer be updated if you break the link with the following template :'
  update: Update
  update_setting_success_message: The setting has been successfully updated
  update_setting_tolerance_error_message: You cannot change the tolerance
  updated_on: Updated on
  user: User
  user_session_management: User session management
  users: Users
  validate: Validate
  view: View
  warning: Warning
  week: Week
  workflow_parameters: Workflows
  workflows: Workflows
  workplace: Workplace
  'yes': 'Yes'
  you_are_offline: You are currently offline, please reconnect.
  your_profile: Your profile
global_fields:
  created_by: Created by
  created_on: Created on
groups:
  created_at: Creation date
  name: Name
  no_result: No results match your search
  placeholder: Search for people or groups to alert
  users: Users
history:
  and_more: and {amount} more...
  assigned: assigned the alert to
  commented: added the following comment
  created: raised an alert
  history: History
  updated: changed the status of the alert
  started: the support request is in the status
incidents:
  resolved_automatically: This incident was resolved automatically using the form.
  raised_incident: raised a new support requestraised a new support request.
  collected_answers: Collected answers
  add_question: Step name...
  add_answer: Response text...
  unsaved_changes_title: This form is not saved
  unsaved_changes_description: Any changes will be lost if you don't save this form
  help_request: Help requests
  add_comment: Comment or specify more details...
  add_comment_on_incident: Please add a comment to help us understand in detail...
  incidents_form: Incidents form
  last_question: Did this form help you resolve your issue?
  help_form_sent_title: Sent successfully
  help_form_sent_content: Your support request was sent successfully.
  no_model_available: there is no support model for the moment.
  modify_data_error: Some required data were not filled correctly.
  modify_data_success: The new form is saved successfully.
  missing_response: Missing response
insights:
  valid_blocks: Valid blocks
  valid_control_points: Valid control points
  valid_controls: Valid controls
instructions:
  incidents: Support requests
  archive: Archive
  create_error: An error occured
  create_error_msg: An error occured please try again later
  created_successfully: Instruction successfully created
  export: Export
  instructions_transfer: Instructions transfer
  modify_instructions: Modify
  new_instruction: New instruction
  no_instructions_found: No instructions found
  save_instruction: Save
  selected: selected
  unarchive: Unarchive
  update_error: An error occured
  update_error_msg: An error occured please try again later
  updated_successfully: Instruction successfully modified
  write_instructions: Write down your instructions for the next operator...
intro:
  aka: Also known as
  desc: Opinionated Vite Starter Template
  dynamic-route: Demo of dynamic route
  hi: Hi, {name}!
  whats-your-name: What's your name?
jobs:
  choose_workplace: Choose your workplace
  choose_workplace_desc: Select your workplace to display the production orders
  create_production_order: Create this production order
  current_jobs: My production orders
  last_10_days: Workorders of the last 10 days
  last_30_days: Workorders of the last 30 days
  no-result: No result for your search
  no_product_order_selected: no fabrication order matches your request, click here to choose a product manually
  not_found: No open production order
  number: Job number
  operation: Operation number
  production_order_doesnt_exist: This production order does not exist!
  select-of: Please select an order number
  select_product: Please select a product
  start_new: Start a new job
  start_new_job_desc_no_jobs: Please start a new production order by clicking on the button at the top right of the screen
  status_finished: Finished
  status_finished_message: The order manufacturing has been finalised
  status_in_progress: In progress
  status_new: New
languages:
  de: German
  en: English
  english: English
  es: Spanish
  fr: French
  french: French
  german: German
  it: Italian
  italian: Italian
  spanish: Spanish
media_library:
  add: Upload a file
  archive_confirmation_message: Are you sure you want to archive the selected attachment
  delete: Remove a file
  delete_confirmation_message: Are you sure you want to delete this file
  failed_upload: we couldn't upload some files due to some errors
  file_update_error_message: Error occurred while connecting to server, please try again.
  media_parameters: Media library
  no_data: No files has been uploaded yet, click in the upload button to add your files
  no_file_yet: Add some files here and upload them on Juno
  no_search_result: No file matches your search query
  no_archived_files: No archived files
  search_file: Upload a file on your computer
  show: Activé
  staged_file: file selected
  staged_files: '1 file has been selected | %{count} files has been selected '
  tab_archived: Archived
  tab_files: Files
  update_file: Update file
  upload: Upload
  upload_files: Upload files
  uploaded_files: 1 files has been uploaded successfully | %{count} files has been uploaded successfully
mes:
  top_5_products: Top 5 products
  flop_5_products: Flop 5 products
  charts:
    synthetic_rate_of_return: OEE
    availability_rate: Availability rate
    quality_rate: Quality rate
    performance_rate: Performance rate
    percentage_of_use: '% of use'
    stop_pareto: Pareto of stops
    scrap_pareto: Pareto of scrap
    slowdown_pareto: Pareto of slowdowns
  my_performance: My performance
  scrap: scrap | scraps
  signal_scrap: Report a scrap
  scrap_quantity: Scrap quantity
  production_rate_expected: Production rate expected
  add_production_rate_expected: Add a production rate
  number_of_pieces: Number of pieces
  pieces: pieces
  show_nc_only: NCs only
  no-nc-today: There's no NC today
  no-nc-in-selected-date: There's no NC in the selected day.
  machine_status_between: The state of the workplace between %{start} and %{end}
  associate-po-to-my-machine:
    description: Please enter the required information to associate a PO to your workplace and view the expected quantity to produce.
    production-order-number-label: PO Number
    production-order-number-placeholder: Enter the PO number...
    quantity-label: Quantity to Produce
    quantity-placeholder: Enter the quantity to produce...
    save: Associate PO
    title: Associate a PO to my workplace
  handle_production:
    start_on_current_session: Start production on the workplace
    confirm_current_production: Confirm that the current order is in production on the workplace
    confirm_finished_production: Confirm that the current order is no longer in production on the workplace
    unlink_env: 'Your production will be unlinked from the following work environment:'
    link_env: 'Your production will be linked to the following work environment:'
    relink_env: 'and will be reattached to this work environment:'
    start: Start my production
    in_progress: Production in progress
    stop: Stop my production
  machines: Workplaces
  date: Date
  compared_to: Compared to
  available: Available
  batch: Batch
  digram-trs: OEE waterfall
  machine: Workplace
  machine-has-no-data: Workplace has no data
  machines-indicators: Indicators by workplace
  net-production-time: Net time
  no-machines-found: No workplaces found
  opened-time: Opened time
  paretor-unknown-stoppage: Downtime pareto
  performances: Performances
  oee: OEE
  availability: Availability
  performance: Performance
  quality: Quality
  po: PO
  production: Production
  production-indicators: Production Indicators
  production-monitoring: Production monitoring
  production-rate: Production rate
  production-rate-delta: Δ prod. rate
  production-time: Prod. time
  quality-waste: Scrap
  production_target_line_chart: Production target
  setting: Settings
  setting-shutdown-reasons: Settings Shutdown Reasons
  all-machines: All workplaces
  settings:
    modal:
      color: Setting Color
      edit:
        title: Edit Shutdown Reason
      machine: Workplace
      new:
        title: New Shutdown Reason
      reason: Shutdown Reason Title
      reason-placeholder: Enter a title...
      stop-machine-reason: Select a workplace
    table:
      color: Color
      machine: Workplace
      status: Status
      title: Title
      produced-parts: Produced parts
      oee: OEE (%)
      availability: Availability (%)
      performance: Performance (%)
      quality: Quality (%)
  total-time: Total time
  unknown-stoppage: Unknown stoppage
  no-available-data: No available data
  full-screen: Full screen
  exit-full-screen-mode: Exit full screen mode
  root_causes:
    title: My root causes
    demo:
      rc_1_title: Poor machine adjustment
      rc_2_title: Changeover
      rc_3_title: Improper machine cleaning
      rc_4_title: Component incompatibility
      rc_5_title: "Supply defect"
      rc_children_1_title: Calibration error
      rc_children_2_title: Improper production speed setting
monthes:
  april: April
  august: August
  december: December
  february: February
  january: January
  july: July
  june: June
  march: March
  may: May
  november: November
  october: October
  september: September
not-found: Not found
notifications:
  activate_feature: Activate or desactivate
  alert_type: Alert notifications
  controls_needed: control to be done | controls to be done
  go_to_task: Go to task
  machine_stopped: Workplace stopped at %{time}. Please immediately specify the reason for this stop.
  qualify_stop: Qualify the stoppage
  reminder_type: Reminder notifications
  undefined_document: Unnamed document
  view: View notification
operator:
  all_workplaces: All workplaces
  all_workplaces_page: All workplaces
  filter_by_workplaces: Filter by workplace
  no_workplaces_recently_opened: You haven't opened yet workplaces
  qualify:
    modal:
      comment: Comment
      comment-placeholder: Add a comment...
      description: A production stoppage has been detected on workplace %{machine} at %{time}. Please provide the following details to qualify the downtime event.
      optional: Optional
      reason: Stoppage Reason
      reason-placeholder: Select a stoppage reason...
      title: Qualify Workplace Downtime
  requalify:
    modal:
      description: Please provide the following information to requalify this stop event.
      reason: Requalification Reason
      reason-placeholder: Select a requalification reason...
      title: Requalify Workplace Stop
  search_workplace: Search a workplace
  search_workplace_message: Refine your search to dislay the right workplaces
  select_all_workplaces: Select all workplaces
  sidebar:
    machine-is-available-again: Workplace Available Again
    machine-is-off: Workplace is off
    machine-monitoring: Workplace Monitoring
    nir: NIR Surveillance
    qualify: Qualify
    requalify: Requalify
    workplace-documents: Job Sheets
  sidebar_nir_monitoring: NIR Monitoring
  sidebar_operations_support: Operations support
  sidebar_workplace_documents: Workplace documents
  workplace: Workplace
  workplace_doesnt_exist: This workplace doesn't exist
  workplaces_recently_opened: Recently opened workplaces
pages:
  alerts: All alerts
  alerts_list: All alerts
  analytics: Analytics
  document: Documents
  form: Forms
  history: History
  library: Library
  media_library: Media library
  monitoring: Monitoring
  reports: All reports
  settings: Settings
  template: Templates
  views: Views
profil:
  description: Manage all personal information related to your JUNO profile
  email: Email adress
  language: Language
  my-profile: My profile
  not_specified: Not specified
  security: Security
  trigram: Factory ID
repetition:
  add: Add a repetition
  additional_filter_nature: Nature
  create_formula: Create a formula
  create_sampling: Create a sampling
  created_successfully: the repetition was created successfully
  created_successfully_message: you can start using the new repetition in your documents
  frequency_list: Frequency list
  creation_error: An unexpected error was encountered
  creation_error_message: An unexpected error was encountered during the creation of your repetition
  event: Event
  formula: Formula
  create_frequency: Create a frequency
  formula_type: Formula type
  frequency_selection_validation_error: You should at least select one option.
  frequency: Frequency
  load_more: Load more
  no_repetition_available: There are no repetition
  notification: Notification
  repeat_every: Repeat every
  repetition_exists_message: A similar repetition already exists
  repetitions: Repetitions
  reset: Reset
  sampling: Sampling
  save: Save
  search: Search
  select: Select a repetition
  select_formula: Select a formula
  times: Times
report:
  double_check_toast: Another operator must perform the verification
  checking_data: Checking data entry
  rechecking_data: Rechecking data entry
  checking_data_desc: Check the value, then validate by pressing ok, or reject by clicking nok if incorrect.
  go_to: Go to
  enter_column: Enter a column...
  nc_columns: Non-conform columns
  more: more
  out_of: out of
  columns: columns
  all_day: All day
  category: Category
  cell_history: History of entries
  cell_history_desc: This field has been modified by one or more users
  checkbox: To do
  control: Control
  controls: Controls
  custom_valid_controls: Valid {header}
  date: Date
  document: Document
  done: Done
  download_failed: Error
  download_failed_message: An error was encountred while working on your document. | An error was encountred while working on your documents.
  download_finished: Download in progress
  download_finished_message: You will receive an email containing the file in a few minutes
  download_limit_reached: Download is limited to 45 reports
  downloading: Downloading...
  downloading_message: We are preparing your document, please wait! | We are preparing your documents, please wait!
  dynamic: Dynamic
  dynamic_target: Dynamic target
  enter_value: Enter value...
  execution_date: Execution date
  filter_invalid: Invalid
  filter_last_3_days: Last 3 days
  filter_last_7_days: Last 7 days
  filter_last_day: Last day
  filter_last_month: Last month
  filter_valid: Valid
  is_valid: Validity
  job: Work order
  job_abbreviation: FO
  justification: Justification
  justify_ko_desc: Please justify the value of the following cell to continue typing on this document
  justify_ko_title: Justification of non-conformity
  last_update: Last save on
  limit_reached: You have reached the limit, please refine your search.
  measure: Measure
  modify_data: Modification of a previously entered field
  modify_data_desc: The data for this cell was entered by another user. This new data will be updated on the grids of each stakeholder on the document.
  modify_data_error: Not all fields are filled in correctly
  modify_data_success: The new data has been successfully registered
  new_data: New data
  no_rows_to_show: Problem to display the data
  no_steps: this file is empty
  operator: Operator
  precise_reasons: Specify the reasons for this modification...
  product: Product
  raise_event: Trigger an event
  report_update_message: The file has been successfully saved
  restore_data: Reset the entry
  restore_data_desc: These data were previously entered before being replaced by new, more recent entries.
  restore_data_title: Do you really want to restore this data?
  see_alert: See alert
  select_option: Please select options
  static: Static
  status: Status
  status_finished: Finished
  status_history: Status history
  status_in_progress: In progress
  status_new: New
  step_answer: Answer
  step_answer_state: State
  step_name: Name
  step_number: Step
  step_photo: Photo
  target: Target
  text: Text
  update_date: Update date
  valid_controls: Valid controls
  view_report: View report
  work_order: Work order
  workplace: Workplace
session:
  all_documents_not_complete: Your documents are not all complete
  all_documents_not_complete_des: You cannot complete the operation until all the mandatory documents have been submitted.
  automatic_reports: Automatic reports
  create_operation: Create new operation
  create_workorder: Create a new workorder
  groups: Groups
  launch_on: Launch on
  of_name_requiered: Please choose a name for your production order
  ongoing_session: Production order started
  ongoing_session_message: You're going to resume a previously started session
  operation_requiered: Please create at least one new operation
  product_requiered: Select at least one product to continue
  refine_search: Refine your search to bring up the associated workorders
  search_po: Search
  select_product: Select product
  certified: Certified
  uncertified: Uncertified
  to_certify: Certify
  certification: Certification
  certification_updated_successfully: Successfully modified team
  add_team: Add a team
  chain_manager: Manager
  select_chain_manager: Select manager
  select_chain_manager_placeholder: Please select the manager
  select_team_members: Select operators
  select_team_members_placeholder: Please select operators
  line_certification_verification: Verification of line certification
  select_team_desc: Selecting your team members
  update: Updated at
  workorder_doesnt_exist: This production order doesn't exist
  no_attachments: New attachments will be displayed here once you add them
settings:
  add_option: Please enter your option and click on enter
  additional_data: Additional data
  alerts: Alerts
  alternative_workplaces: Alternatives workplaces
  audit_logs:
    action: Action
    action_hour: Triggered at
    deactivate: Deactivation
    get_more: Get + { count } entry | Get + { count } entries
    load_more: More entries
    login: Login
    logout: Logout
    name: Associated account
    reactivate: Activation
  blocker: Blockers
  cancel: Cancel
  color: Color
  conception_rights: Conception rights
  create_ftp_success_message: The server FTP has been successfully saved
  create_group: Create a group
  create_lookup: Create a new lookup
  create_product: Create a new product
  create_role: Create role
  create_setting_success_message: The parameter has been successfully created
  create_status: Create a status
  create_workplace: Create a new workplace
  decimal_values: Decimal values
  default: Défaut
  delete: Delete parameter
  delete_lookup_description: Are you sure you want to archive the ?
  delete_lookup_title: Archive data
  delete_option: Delete option
  delete_option_confirmation_message: Are you sure you want to delete this option? This action cannot be undone.
  delete_product_description: Are you sure you want to delete this product ?
  delete_product_title: Archive a product
  delete_setting_success_message: The setting has been successfully deleted
  delete_workplace_description: Are you sure you want to delete this workplace ?
  delete_workplace_title: Archive a workplace
  disabled: Disabled
  document_category: Document category
  document_status: Document status
  enable_tolerance: Enable Tolerance in Metrics
  enabled: Enabled
  ending_time: Ending time
  exigence: Exigence
  export:
    products: Export products data
    workplaces: Export workplaces data
  formula: Formula
  frequency: Fréquence
  get_more_document_category: Get { count } more category | Get { count } more categories
  get_more_grid_header: Get { count } more header | Get { count } more headers
  get_more_list_options: Get { count } more list option | Get { count } more list options
  get_more_means_of_measure: Get { count } more mean of measure | Get { count } more means of measure
  get_more_messages: Get { count } more message | Get { count } more messages
  get_more_step_tag: Get { count } tag | Get { count } tags
  get_more_teams: Get { count } more team | Get { count } more teams
  get_more_units_of_measure: Get { count } more unit | Get { count } more units
  get_more_workflow_models: Get { count } more template | Get { count } more templates
  grid_header: Grid header
  groups: Groups
  header_list: Sequence
  import:
    products: Upload file for importing products
    workplaces: Upload file for importing workplaces
  input_modification: Input modification
  input_parameters: Inputs
  is_selected: Is Selected
  justify_ko: NC Justification
  list_options: List options
  list_type: List type
  list_value: List value
  load_more_document_category: Get more categories
  load_more_grid_header: Get more headers
  load_more_list_options: Get more list options
  load_more_means_of_measure: Get more means of measure
  load_more_messages: Get more messages
  load_more_step_tag: Get more tags
  load_more_teams: Get more teams
  load_more_units_of_measure: Get more units
  load_more_workflow_models: Get more templates
  lookup_exists: A similar data record already exists, please modify one of the fields.
  lookup_keys_error: The file configuration doesn’t match the keys for this type. Please contact your operations manager
  lookup_tables: Lookup table
  mandatory_column: This field is mandatory
  mandatory_fields: Please be sure to fill all the mandatory fields
  mandatory_finish: Complete inputs
  maximum_inactivity_time: Inactivity time before automatic logout
  enable_incativity_logout: Logout after inactivity
  means_of_measure: Means of measure
  measure_unit: Measure unit
  media_library: Active
  members: Members
  message: Content
  mold: Mold
  name: Name
  nature: Nature
  new_status: New status
  new_user: New user
  no_user_found: No user has been found
  number_of_days_of_validity: Number of days the current password is valid
  number_of_decimals: Number of significant digits
  number_of_columns_per_page: Number of columns per page
  operator_session: Session
  order: Order
  organization: Organisation
  parameter_name: Name
  parameter_value: Value
  password_expiration: Password expiration
  plan: Plan
  product: Product
  profile: Profile
  repetition: Repetition
  repetition_type: Repetition type
  report_status: Report status
  reset_automatically: Reset password automatically
  roles: Roles & rights
  roles_access: Available to
  roles_cannot_delete_description: '{amount} user(s) own this role. Please assign them a different role or delete them beforehand'
  roles_cannot_delete_title: Cannot delete this role
  roles_change-status: Change Status
  roles_confirm_delete_description: By confirming, this role will be permanently removed. This action is irreversible.
  roles_confirm_delete_title: Confirm removal ?
  roles_create: Create
  roles_delete: Archive
  roles_edit_name: Edit name
  roles_new: New role
  roles_read: Read
  roles_ressource: Resource
  roles_write: Edit
  same_as_tolerance: Same as tolerance
  sample: Sample
  sampling: Sampling
  save_status: Save status
  search_user_placeholder: Search and add new members
  settings_error: A problem was encountered while connecting to the server, please try again
  starting_time: Starting time
  status_color: Color
  status_icon: Icon
  status_name: Name
  status_saved: Status has been successfully saved!
  step_tag: Tag
  team: Team
  team_name: Team name
  theTeam: The team
  tolerance: Tolerance
  type: Type
  unit: Unit
  units_of_measure: Units of measure
  update_group: Update a group
  update_lookup: Update lookup
  update_password_message_description: the user will have to reconnect using the new password
  update_password_message_title: Do you confirm changing the user's password ?
  update_product: Update a product
  update_setting_error_message: An error occured. The parameter has not been updated.
  update_setting_success_message: The parameter has been successfully updated
  update_workplace: Update a workplace
  users: Users
  users_and_access: Users and access
  value: Value
  value_exists: This name exists
  value_tolerance: Tolerance value
  workflow: Workflow
  workplaces:
    export: Export the list of workplaces in csv format
    import: Import a csv file
sidebar:
  documents: Documents
  handle_documents: Manage documents
  incidents: Incidents
  manage_production: Manage production
  manage_projects: Manage my projects
  media_library: Media library
  performance_analysis: Performance analysis
  performance_analysis_production: Production
  performance_analysis_quality: Quality
  production_monitoring: Production monitoring
  production_order_monitoring: PO monitoring
  incident_form: Incidents forms
simulation:
  empty_simulation: You don't have any simulations yet. You can start creating them.
  new_simulation: New simulation
sso:
  authentication_error: An error was encountered while connecting to the sso provider, please check with your administrator
  connect: Connect with your SSO provider
  microsoft_connection: Microsoft connection (SSO)
status:
  created: The status has been created successfully
  deleted: The status has been deleted successfully
  status: Status
  statuses: Statuses
  updated: The status has been updated successfully
messages:
  no_data: No data available
step:
  absolute_value: Absolute value
  add_condition: Add a condition
  add_file: Attach a file
  add_one_or_multiple_files: Attach one or more files
  answer_nok: 'No'
  answer_ok: 'Yes'
  answer_state: State
  boolean: Yes/No
  checkbox: Checkbox
  create_answer: Create answer
  requested_entries: Requested entries
  create_calcul_placeholder: Create your calculation by adding required inputs and mathematical operators.
  calculation_step: Calculation step
  fill_calcul_btn: Make a calculation
  fill_calcul_title: Fill in the following fields to obtain the calculation result
  calcul_preview: Calculation preview
  block: Block
  mathematical_operators: Mathematical operators
  delete_all: Delete all
  create_calculation: Create a calculation
  incorrect_calcul: Calculation error
  preview_calculation: Preview calculation
  customized_answer: Customized answer
  delete_step_description: This step cannot be deleted, as it is dependent on a conditional step. Please unlink them first.
  delete_step_calcul_description: This step cannot be deleted, as it is dependent on a calculation block.
  delete_step_title: This step cannot be deleted
  description_placeholder: Enter a description
  done: done
  file: file
  file_upload_issue: A problem was encountered when loading the file
  link: link
  list: List
  mandatory: mandatory
  double_check: Double check
  margin: Margin
  means: Measurement means
  measure: Measure
  measure_max: Max
  measure_min: Min
  measure_target: Target
  measure_tolerance: +/-
  measure_unit: Unit
  multiple_selections: Multiple selections
  name_placeholder: Enter a name
  napossible: N/A
  no_step_available: No step available
  percentage: Percentage
  select_condition_item_in_list: Select an option...
  select_condition_step_in_list: Select steps to display...
  set_value: Set value
  take: Times
  uncertainty: Uncertainty
template:
  archive: Archive template
  archive_template: Make it archived
  are_you_sure_edit: Changing this template will change all related document, are you sure you want to continue?
  create: New template
  delete_confirmation_message: Are you sure you want to archive this template?
  modify: Modify the template
  new_template: New template
  publish_template: Make it published
  reference: Reference
  search: Search a template
  template_delete_used_reference: Archiving the template is irreversible and will lead to modifications in  the following documents
  template_name: Template name
  template_update_documents_error_message: Some documents are not updated correctly, would you please try again.
  template_update_error_message: An error has occurred when saving the template
  template_update_message: The template has been successfully modified
  template_used_reference: 'Any modification of the template is irreversible and will lead to modifications in the following documents:'
  unlink_template: Unlink
trigger:
  day: Day
  production_order: Production order
  team: Team
user:
  active: Active
  automatic_deactivation: Automatic deactivation
  by: by
  client: Client
  confirmation_description_activate_user: The operator will have access to JUNO again.
  confirmation_description_desactivate_user: The user will no longer be able to access JUNO once their account is deactivated.
  confirmation_title_activate_user: Are you sure you want to reactivate {name} account ?
  confirmation_title_desactivate_user: Are you sure you want to deactivate {name} account ?
  deactivate: Disable
  deactivate_account: Deactivate the account
  deactivated_successfully: The account has been deactivated successfully
  disable_account: Disable account
  disabled: Disabled
  disabled_at: Disabled at
  disabled_automatically_at: Disabled automatically at
  edit_information: Edit informaton
  email: Email
  first_name: First name
  language: Language
  last_name: Last name
  load_more_groups: More reports
  modify_password: Modify password
  nfc_authentication_status: NFC authentication status
  nfc_code: NFC code
  nfc_status: NFC status
  nfc_text_validity: Test NFC validity
  password: Password
  personal_information: Personal Information
  reactivate: Reactivate
  reactivate_account: Reactivate account
  reactivated_successfully: The account has been reactivated successfully
  desactivate: Deactivate
  role: Role
  role_attribution_mandatory: Role assignment is mandatory
  site_attribution_mandatory: Site assignment is mandatory
  user_certified: User certification
  operator_certified: Certified operator
  chain_manager_certified: Certified manager
  site: Site
  sso_authentication: Single sign on (SSO)
  sso_only_error: Only the SSO authentication is active for this user
  status: Status
  temporary: Temporary
  temporary_account: Temporary account
  unknown_creator: Unknown creator
  updated: User has been updated
  use_sso_only: By activating this button, the user must have an email address and will not be able to connect other than with this address.
users:
  get_more: Get { count } more user | Get { count } more users
  load_more: More users
  readonly_mode: Readonly mode
validate_nc: Validate NC
validations:
  document_category: Category should not be empty
  document_description: Document description is missing
  document_name: Document name is missing
  minimum_characters: Please enter at least {length} characters
views:
  add: Create new view
  delete: Delete report
  delete_confirmation_message: Are you sure you want to delete this view ?
  delete_modal_title: Delete a view
  description: Report description
  error_create: An error occurred while creating the view
  error_delete: An error occurred while deleting the view
  error_empty_title: The title must not be empty
  error_update: An error occurred while saving the view
  filters: Active filters
  get_more_reports: Get { count } more report | Get { count } more reports
  load_more_reports: More reports
  name: Report name
  new_vue_template_description: Enter a description here
  new_vue_template_title: New report
  no_filters_selected: No filters selected
  no_group: No group
  save: Save report
  save_view: Save
  saved_views: Saved reports
  title: Reports
  view_all: View all
  view_created: A new view has been successfully created
  view_delete: View has been successfully deleted
  view_updated: View has been successfully modified
welcome_url:
  analytics: Analytics
  conception: Conception
  designer_space: Supervision space
  library: Library
  operation: Operation
  operator_all_workplaces: Operator space (all workplaces)
  operator_free: Workplaces
  operator_selection: Workplace selection
  operator_space: Production space
  select_option: Select...
  space: Space
  supervision: Supervision
  welcome_page: Welcome page
workflows:
  add: Add a step
  add_description: Add description
  associated_permissions: Associated permissions
  associated_status: Associated status
  built_in: Default workflow
  create: Create a new workflow
  created: Workflow has been successfully created
  customizable: Customizable workflows
  default: Default workflow
  default_alert_step_1: New alert
  default_alert_step_2: Alert in progress
  default_alert_step_3: Alert resolved
  default_document_step_1: Document in progress
  default_document_step_2: Document published
  default_of_step_1: New production order
  default_of_step_11: Production order released
  default_of_step_2: Production order in progress
  default_of_step_3: Production order completed
  default_operations_step_1: New operation
  default_operations_step_2: Operation in progress
  default_operations_step_3: Finish operation
  default_report_step_1: New report
  default_report_step_2: Report in progress
  default_report_step_3: Report finished
  description: Description
  error: An error occured while creating/updating the workflow
  error_inputs: Please fill all the required fields to validate the new step
  name: Workflow name
  new_workflow_title: New workflow
  no_workflow: No workflow
  not_found: No workflow found
  not_found_description: Create a new workflow by clicking on the button at the top right of the screen
  save: Save workflow
  search_status: Search status...
  select_option: Select a status
  select_workflow: Select a workflow
  select_workflow_type: Select a workflow type in order to create a new workflow
  templates: Templates
  title: Workflows
  incident: Incident
  type: Type
  type_alerts: Alert
  type_documents: Document
  type_of: Production order
  type_operations: Operation
  type_reports: Report
  type_select: Select a workflow type
  unnamed: Unnamed workflow
  updated: Workflow has been successfully updated
workorder:
  complete: Finish the operation
  confirm_modal_description: You are about to complete the operation, have you completed all your documents?
  confirm_modal_title: End and close the current operation
  set_operation_in_progress: Set operation in progress
incident_form:
  new: New form
  new_step: New step
  title: Incident forms
  my_forms: My forms
  remove_branch: Are you sure you want to delete this branch?
  remove_branch_description: This action cannot be undone. All values associated with this branch will be lost.
simulator:
  generate: Generate
  derivation: Derivation
  derivation_data: Derivation data
  available_only_for_pilot_mode: Available only in pilot mode
projects:
  create_new: New project
  all_projects: All projects
  assignees: Assignees
  dates: Dates
  deadline: Deadline
  priority:
    title: Priority
    low: Low
    medium: Medium
    high: High
    tbd: To be done
  progress: Progress
  budget: Budget
  title: Project
  information: Project information
  localisation: Location
  new: New project
  assignees_placeholder: No assignee
  name_placeholder: Give a name to the project
  budget_placeholder: Give a cost to the project
  description_placeholder: What is this project about?
  deadline_placeholder: No date
  priority_placeholder: What is the priority of this project?
  localisation_placeholder: Where is this project located?
  demo:
    project_1_title: Improvement of machine availability
    project_1_description: "Improve the efficiency and quality of the changeover process to reduce downtime, quality defects, and productivity losses"
    project_2_title: Reduce the scrap rate
    project_3_title: Reduce delivery time
  filters:
    day_hour: Day/Hour
    week_day: Week/Day
    month_day: Month/Day
    year_month: Year/Month
    year: Year
  actions:
    all_actions: All actions
    activity: Activity
    create_new: New action
    deliverable: Deliverable
    add_delivery_description_placeholder: Enter a description...
    add_delivery_file: Attach a file
    activity_all: All
    activity_comment: Comments
    activity_status: Status history
    add_new: Add an action
    demo:
      action_1_title: Train the staff
      action_1_description: "Data collection: Gather data on downtime and errors during previous changeovers."
      action_2_title: Implement additional quality controls
      action_3_title: Automate manual and repetitive tasks in the order processing
      action_4_title: "Involve operators in identifying and solving issues related to scrap"
      action_5_title: "Seek customer feedback on their delivery experience and identify areas for improvement"
      action_6_title: "Analyze downtime"
      action_7_title: Implement preventive maintenance
      action_8_title: Improve spare parts management
      action_9_title: "Optimize supply chain"
    project: Project
    title: Project actions
    history:
      assigned: assigned the action to
      commented: commented
      created: created the action
      updated: changed the status of the action
    project_placeholder: Add the action to projects
    category_placeholder: No category
    name_placeholder: Give a name to the action
    no_description: No description provided.
    description_placeholder: What is this action about?
