import type { StepTag } from './documentSettings'
import { hydrate } from '~/utils/object'

export enum StepType {
  default = 'default',
  Boolean = 'Boolean',
  Measure = 'Measure',
  Link = 'Link',
  Checkbox = 'Checkbox',
  Text = 'Text',
  Time = 'Time',
  Number = 'Number',
  List = 'List',
  File = 'File',
  Photo = 'Photo',
  Calculator = 'Calculator',
}

export const NumberStepTypes = [
  StepType.Number,
  StepType.Measure,
  StepType.Calculator,
]

export const StepTypesWithBranching = [
  StepType.List,
  StepType.Boolean,
  StepType.Measure,
  StepType.Calculator,
]

export enum SpecialStepBranchingValues {
  Valid = 'valid',
  NonValid = 'non_valid',
  Uncertain = 'uncertain',
}

export enum StepUncertaintyOptions {
  PERCENTAGE = 'percentage',
  ABSOLUTE_VALUE = 'absolute_value',
}

export enum AdditionalFilter {
  nature = 'nature',
}

export enum BranchingActionType {
  display = 'display',
}

export enum StepMeasureOptions {
  SetValue = 'set_value',
  MeasureTarget = 'measure_target',
  MinusInf = '-ꝏ',
  Inf = '+ꝏ',
  Static = 'static',
  Dynamic = 'dynamic',
  Percentage = 'percentage',
  AbsoluteValue = 'absolute_value',
  Margin = 'margin',
}

export enum StepMeasureType {
  Target = 'target',
  Uncertainty = 'uncertainty',
  Min = 'min',
  Max = 'max',
  MIN_UNCERTAINTY = 'min_uncertainty',
  MAX_UNCERTAINTY = 'max_uncertainty',
}

export class StepAnswer {
  value = ''
  updated_by = ''
  update_date = 0
  is_valid = true
  reason = ''
  type = ''
  row_id = 0
  double_check_result = false
  double_checked_by = ''
  double_checked_at = ''
  col_id = 0
}

export class StepAttachment {
  id = ''
  name = ''
  path = ''
  viewable = false
  viewable_file_signed_url = ''
  constructor(
    id: string,
    name: string,
    path: string,
    viewable = false,
    viewable_file_signed_url = '',
  ) {
    this.id = id
    this.name = name
    this.path = path
    this.viewable = viewable
    this.viewable_file_signed_url = viewable_file_signed_url
  }
}

export class StepBranching {
  trigger = {} as Trigger
  action = BranchingActionType.display
  action_details = {} as ActionDetails
}

export class ActionDetails {
  steps_to_display = [] as any
}
export class Trigger {
  conditions = [] as Condition[]
}

export class Condition {
  operator = ''
  value = ''
}

export class JStep {
  [x: string]: any
  id = ''
  client_id = ''
  site_id = ''
  creation_date: any = ''
  name = ''
  description = ''
  descriptionLineLength = 0
  description_height = 0
  is_mandatory = true
  double_check = false
  num_step = 0
  branching = [] as StepBranching[]
  col_ids_to_disable = [] as number[]
  files_attached = [] as StepAttachment[]
  type = StepType.default
  repetitions: Array<any> = []
  answers: StepAnswer[] = [] as StepAnswer[]
  last_sampling_areas = [] as boolean[]
  updatedShiftIndex = 0
  tags: StepTag[] = []
  sourceStepId = ''
  linkedToSource = false
  templateName = ''
  templateId = ''
  file_info = {}
  hidden = [] as boolean[]
  isHidden = false
  temporary = false

  // Versioning
  root_version_step_id = ''
  next_version_step_id?: string = ''
  previous_version_step_id?: string = ''

  constructor() {}
}

export const buildStep = (step: JStep) => {
  switch (step.type) {
    case StepType.Boolean:
      return new JStepBoolean(step)
    case StepType.Measure:
      return new JStepMeasure(step)
    case StepType.Link:
      return new JStepFile(step)
    case StepType.Checkbox:
      return new JStepCheckbox(step)
    case StepType.Text:
      return new JStepText(step)
    case StepType.Time:
      return new JStepTime(step)
    case StepType.List:
      return new JStepList(step)
    case StepType.File:
      return new JStepAttachment(step)
    case StepType.Number:
      return new JStepNumber(step)
    case StepType.Photo:
      return new JStepPhoto(step)
    case StepType.Calculator:
      return new JStepCalculator(step)
    default:
      return new JStep()
  }
}

export class JStepBoolean extends JStep {
  [x: string]: any

  type: StepType = StepType.Boolean
  is_not_applicable = false
  means_of_measure = ''

  constructor(object: typeof JStepBoolean | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepAttachment extends JStep {
  [x: string]: any

  type: StepType = StepType.File
  link = ''
  file_name = ''

  constructor(object: typeof JStepBoolean | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepLastTargets {
  [x: string]: any

  init_col_id = 0
  last_col_id = 0

  constructor(object: typeof JStepLastTargets | {}) {
    hydrate(this, object)
  }
}

export class JStepMeasure extends JStep {
  [x: string]: any

  unit = ''
  type: StepType = StepType.Measure
  range: [number, number] = [null, null]
  goal = null
  extent = null
  means_of_measure = ''
  is_dynamic = false
  last_targets = [] as JStepLastTargets[]
  min_type = StepMeasureOptions.SetValue
  max_type = StepMeasureOptions.SetValue
  target_selected_option = StepMeasureOptions.Static
  uncertainty_min = null
  uncertainty_max = null
  uncertainty_enabled = false
  uncertainty_type = StepMeasureOptions.Percentage

  constructor(object: typeof JStepMeasure | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepNumber extends JStep {
  value = 0

  constructor(object: typeof JStepNumber | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepFile extends JStep {
  [x: string]: any

  linkFile = ''
  type: StepType = StepType.Link

  constructor(object: typeof JStepFile | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepCheckbox extends JStep {
  [x: string]: any

  type: StepType = StepType.Checkbox
  isChecked = false

  constructor(object: typeof JStepCheckbox | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepText extends JStep {
  [x: string]: any

  type: StepType = StepType.Text
  text = ''

  constructor(object: typeof JStepText | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepList extends JStep {
  [x: string]: any

  type: StepType = StepType.List
  list_data = {
    list_id: null,
    list_type: '',
    is_multiple: false,
  }

  constructor(object: typeof JStepList | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepTime extends JStep {
  [x: string]: any

  type: StepType = StepType.Time
  date = ''

  constructor(object: typeof JStepTime | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepPhoto extends JStep {
  [x: string]: any

  type: StepType = StepType.Photo
  url = ''

  constructor(object: typeof JStepPhoto | {}) {
    super()
    hydrate(this, object)
  }
}

export class JStepCalculator extends JStep {
  [x: string]: any

  type: StepType = StepType.Calculator
  calcul = ''
  is_measure = false
  unit = ''
  range: [number, number] = [null, null]
  goal = null
  extent = null
  means_of_measure = ''
  is_dynamic = false
  last_targets = [] as JStepLastTargets[]
  min_type = StepMeasureOptions.SetValue
  max_type = StepMeasureOptions.SetValue
  target_selected_option = StepMeasureOptions.Static
  uncertainty_min = null
  uncertainty_max = null
  uncertainty_enabled = false
  uncertainty_type = StepMeasureOptions.Percentage

  constructor(object: typeof JStepCalculator | {}) {
    super()
    hydrate(this, object)
  }
}
