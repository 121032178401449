import type { JUser } from '~/models/users/JUser'

export class ContextScopeViewModel {
  quantity = 0
  tags = {} as any
  currentUser = {} as JUser

  constructor(object: ContextScopeViewModel | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}
